{
  "SALES_AGENT": [
    {
      "link": "/team-dashboard",
      "label": "Team Dashboard"
    },
    {
      "link": "/pipeline",
      "queryParams": {"today": true},
      "label": "Pipeline"
    },
    {
      "link": "/search-flights",
      "label": "Search Flights"
    },
    {
      "link": "/clients",
      "label": "Clients"
    },
    {
      "link": "/leads",
      "label": "Leads"
    },
    {
      "link": "/flight-requests",
      "label": "Flight Requests"
    },
    {
      "link": "/orders",
      "label": "Orders"
    },
    {
      "link": "/upcoming-flights",
      "label": "Upcoming Flights"
    },
    {
      "link": "/support-requests",
      "label": "Support requests"
    },
    {
      "link": "/airline-classes",
      "label": "Airline Classes",
      "featureToggleOn": "airline_classes_administrator"
    },
    {
      "link": "/miles-programs",
      "label": "Miles Programs",
      "featureToggleOn": "edit_miles_program_price"
    }
  ],
  "SALES_TEAM_LEAD": [
    {
      "link": "/insights",
      "label": "Insights"
    },
    {
      "link": "/team-dashboard",
      "label": "Team Dashboard"
    },
    {
      "link": "/pipeline",
      "queryParams": {"today": true},
      "label": "Pipeline"
    },
    {
      "link": "/search-flights",
      "label": "Search Flights"
    },
    {
      "link": "/clients",
      "label": "Clients"
    },
    {
      "link": "/leads",
      "label": "Leads"
    },
    {
      "link": "/flight-requests",
      "label": "Flight Requests"
    },
    {
      "link": "/orders",
      "label": "Orders"
    },
    {
      "link": "/upcoming-flights",
      "label": "Upcoming Flights"
    },
    {
      "link": "/support-requests",
      "label": "Support requests"
    },
    {
      "link": "/airline-classes",
      "label": "Airline Classes",
      "featureToggleOn": "airline_classes_administrator"
    },
    {
      "link": "/miles-programs",
      "label": "Miles Programs",
      "featureToggleOn": "edit_miles_program_price"
    }
  ],
  "SEARCHER": [
    {
      "link": "/my-queue",
      "label": "My Queue"
    },
    {
      "link": "/search-flights",
      "label": "Search Flights"
    },
    {
      "link": "/flight-requests",
      "label": "Flight Requests"
    },
    {
      "link": "/airline-classes",
      "label": "Airline Classes",
      "featureToggleOn": "airline_classes_administrator"
    },
    {
      "link": "/miles-programs",
      "label": "Miles Programs",
      "featureToggleOn": "edit_miles_program_price"
    }
  ],
  "TICKETING_AGENT": [
    {
      "link": "/pipeline",
      "label": "To do"
    },
    {
      "link": "/search-flights",
      "label": "Search Flights"
    },
    {
      "link": "/support-requests",
      "label": "Support Requests"
    },
    {
      "link": "/orders",
      "label": "Orders"
    },
    {
      "link": "/miles-inventory",
      "label": "Miles Inventory"
    },
    {
      "link": "/upcoming-flights",
      "label": "Upcoming Flights"
    },
    {
      "link": "/invoices",
      "label": "Invoices"
    },
    {
      "link": "/airline-classes",
      "label": "Airline Classes",
      "featureToggleOn": "airline_classes_administrator"
    },
    {
      "link": "/miles-programs",
      "label": "Miles Programs",
      "featureToggleOn": "edit_miles_program_price"
    },
    {
      "link": "/miles-transfers",
      "label": "Miles Transfers"
    }
  ],
  "ACCOUNTING": [
    {
      "link": "/invoices",
      "label": "Invoices"
    },
    {
      "link": "/bookings",
      "label": "Bookings"
    },
    {
      "link": "/payments",
      "label": "Payments"
    },
    {
      "link": "/orders",
      "label": "Orders"
    },
    {
      "link": "/costs",
      "label": "Costs"
    },
    {
      "link": "/miles-inventory",
      "label": "Miles Inventory"
    },
    {
      "link": "/airline-classes",
      "label": "Airline Classes",
      "featureToggleOn": "airline_classes_administrator"
    },
    {
      "link": "/miles-programs",
      "label": "Miles Programs",
      "featureToggleOn": "edit_miles_program_price"
    },
    {
      "link": "/miles-transfers",
      "label": "Miles Transfers"
    }
  ],
  "ADMINISTRATOR": [
    {
      "link": "/users",
      "label": "Users"
    },
    {
      "link": "/airline-classes",
      "label": "Airline Classes",
      "featureToggleOn": "airline_classes_administrator"
    },
    {
      "link": "/miles-programs",
      "label": "Miles Programs",
      "featureToggleOn": "edit_miles_program_price"
    }
  ],
  "ROBOT": []
}
