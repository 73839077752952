import { Injectable } from '@angular/core';
import { Call } from '@twilio/voice-sdk';
import { filter, switchMap, take } from 'rxjs';
import { UserStatusType, UserStatusTypeEnum } from '@app/generated/graphql';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import { RdrAuthService } from '@app/auth/services/rdr-auth.service';
import {
  AvailabilityService,
  AvailableStatus,
} from '@calls/services/availability/availability.service';
import { TwilioService } from '@calls/services/twilio/twilio.service';
import { RdrToastService } from '@app/ui/services/rdr-toast.service';
import { CallPopupService } from '@calls/services/call-popup/call-popup.service';
import { FeatureToggleService, RdrFeature } from '@app/ui/services/feature-toggle.service';
import { UsersService } from '@app/pages/users/services/users.service';

@Injectable({
  providedIn: 'root',
})
export class CallsService {
  constructor(
    private availabilityService: AvailabilityService,
    private callPopupService: CallPopupService,
    private twilioService: TwilioService,

    private cognitoAuthService: CognitoAuthService,
    private featureToggleService: FeatureToggleService,
    private rdrToastService: RdrToastService,
    private rdrAuthService: RdrAuthService,
    private usersService: UsersService,
  ) {}

  initCalls() {
    this.cognitoAuthService
      .hasCognitoSession()
      .pipe(
        filter((val) => Boolean(val)),
        take(1),
        switchMap(() => this.rdrAuthService.getUser()),
      )
      .subscribe(() => {
        const callsEnabled = this.featureToggleService.isFeatureEnabled(RdrFeature.twilio_service);

        if (callsEnabled) {
          this.initTwilio();
        }
      });
  }

  initTwilio() {
    const availableStatus =
      this.cognitoAuthService.user.currentStatus === UserStatusType.Online
        ? AvailableStatus.Available
        : AvailableStatus.Unavailable;
    if (availableStatus === AvailableStatus.Available) {
      this.login();
    }
  }

  handleIncomingCall(call: Call) {
    this.callPopupService.setIncomingCall(call);
  }

  startCalls() {
    this.login();
  }

  handleLoginSuccess = () => {
    this.availabilityService.startPolling();
    this.updateUserStatus(UserStatusTypeEnum.Online);
  };

  handleLoginFailed = () => {
    this.availabilityService.setStatusReconnecting();
    window.setTimeout(() => this.login(), 15000);
  };

  login() {
    this.handleLoginSuccess();
  }

  stopCalls() {
    this.twilioService.logout();
    this.availabilityService.stopPolling();
    this.updateUserStatus(UserStatusTypeEnum.Offline);
  }

  updateUserStatus(status: UserStatusTypeEnum) {
    const params = { input: { status } };
    this.rdrAuthService
      .getUser('network-only')
      .pipe(
        filter((user) => user?.callProfile?.status !== (status as unknown as UserStatusType)),
        switchMap(() => this.usersService.setUserStatus(params)),
      )
      .subscribe();
  }

  showError() {
    this.rdrToastService.error({ message: 'Twilio connection failed' });
  }
}
