import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormSubmitData } from '@shared/components/radar-form-abstract';
import { UntypedFormGroup } from '@angular/forms';
import { SEARCH_INPUT } from '@app/formly/helpers/fields';
import { FormlyFieldConfig } from '@ngx-formly/core';

export type GlobalSearchForm = {
  search: string;
};

@Component({
  selector: 'global-search-form',
  templateUrl: './global-search-form.component.html',
  styleUrls: ['./global-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchFormComponent {
  @Input() formModel = {
    search: '',
  };
  @Output() formSubmit: EventEmitter<FormSubmitData> = new EventEmitter<FormSubmitData>();

  formGroup = new UntypedFormGroup({});

  fields: FormlyFieldConfig[] = [
    SEARCH_INPUT({
      key: 'search',
      props: {
        placeholder: 'Search',
      },
    }),
  ];

  submitForm() {
    this.formSubmit.emit({
      data: this.formModel,
    });
  }
}
