import { Injectable } from '@angular/core';
import {
  AlertNode,
  AlertsGQL,
  LimitOffsetInput,
  MarkAlertReadGQL,
  MarkAlertReadMutationVariables,
  MarkAllAlertsReadGQL,
  MarkAllAlertsReadMutationVariables,
  UnreadCountAlertsGQL,
} from '@app/generated/graphql';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  constructor(
    private alertsGQL: AlertsGQL,
    private unreadCountAlertsGQL: UnreadCountAlertsGQL,
    private markAllAlertsReadGQL: MarkAllAlertsReadGQL,
    private markAlertReadGQL: MarkAlertReadGQL,
  ) {}

  getAlerts(input: LimitOffsetInput) {
    return this.alertsGQL
      .fetch({ input })
      .pipe(map(({ data }) => (data.alertsList as AlertNode[]) || []));
  }

  getUnreadCountAlerts() {
    return this.unreadCountAlertsGQL.fetch().pipe(map(({ data }) => data.unreadAlertsCount));
  }

  markAllAlertsRead(params?: MarkAllAlertsReadMutationVariables) {
    return this.markAllAlertsReadGQL
      .mutate(params)
      .pipe(map(({ data }) => (data?.markAllAlertsRead?.result.alerts as AlertNode[]) || []));
  }

  markAlertRead(params: MarkAlertReadMutationVariables) {
    return this.markAlertReadGQL
      .mutate(params)
      .pipe(map(({ data }) => (data?.markAlertRead?.result.alerts as AlertNode[]) || []));
  }
}
