import { concatMap } from 'rxjs';
import { CognitoAuthService } from '../auth/services/cognito-auth.service';
import { RdrAuthService } from '../auth/services/rdr-auth.service';
import { UserType } from '../generated/graphql';
import { AppInjector } from '../app-injector.service';

export enum ModuleTypes {
  Pipeline = 'Pipeline',
  SupportRequests = 'SupportRequests',
}

type LazyModules = { [key in ModuleTypes]: Partial<{ [T in UserType]: () => Promise<unknown> }> };

const lazyModules: LazyModules = {
  Pipeline: {
    [UserType.SalesAgent]: () => {
      return import('./pipeline/pipeline.module').then((m) => m.PipelineModule);
    },
    [UserType.SalesTeamLead]: () => {
      return import('./pipeline/pipeline.module').then((m) => m.PipelineModule);
    },
    [UserType.TicketingAgent]: () => {
      return import('./pipeline-ticketing/pipeline-ticketing.module').then(
        (m) => m.PipelineTicketingModule,
      );
    },
    [UserType.Searcher]: () => {
      return import('./flight-requests/flight-requests.module').then((m) => m.FlightRequestsModule);
    },
  },
  SupportRequests: {
    [UserType.SalesAgent]: () => {
      return import('./support-requests/support-requests.module').then(
        (m) => m.SupportRequestsModule,
      );
    },
    [UserType.SalesTeamLead]: () => {
      return import('./support-requests/support-requests.module').then(
        (m) => m.SupportRequestsModule,
      );
    },
    [UserType.TicketingAgent]: () => {
      return import('./support-requests-ticketing/support-requests-ticketing.module').then(
        (m) => m.SupportRequestsTicketingModule,
      );
    },
  },
};

export const getLazyModule = (module: ModuleTypes) => () => {
  const cognitoAuthService = AppInjector.injector.get(CognitoAuthService);
  const rdrAuthService = AppInjector.injector.get(RdrAuthService);

  return new Promise((resolve) =>
    cognitoAuthService
      .hasCognitoSession()
      .pipe(concatMap(() => rdrAuthService.getUser()))
      .subscribe((user) => {
        const type = user?.type as UserType;
        const promiseModule = lazyModules[module][type] as () => Promise<unknown>;
        if (promiseModule) {
          void promiseModule().then((m) => resolve(m));
        }
      }),
  );
};
