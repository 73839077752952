import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, switchMap, timer } from 'rxjs';
import { AlertsService } from '../../services/alerts.service';
import { pollInterval } from '../../utils/config';

@Component({
  selector: 'alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsComponent implements OnInit {
  unreadCountAlerts$: Observable<any>;

  showedAlerts: boolean;

  constructor(private alertsService: AlertsService) {}

  ngOnInit() {
    if (localStorage.getItem(DISABLED_ALERTS_KEY)) return;

    this.initUnreadCountAlertsPolling();
  }

  initUnreadCountAlertsPolling() {
    this.unreadCountAlerts$ = timer(0, pollInterval).pipe(
      switchMap(() => this.alertsService.getUnreadCountAlerts()),
    );
  }
}

export const DISABLED_ALERTS_KEY = 'disabledAlertsPolling';
