import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import { RdrAuthService } from '@app/auth/services/rdr-auth.service';
import { FeatureToggleService, RdrFeature } from '@app/ui/services/feature-toggle.service';
import { RdrToastService } from '@app/ui/services/rdr-toast.service';
import { Observable, concatMap, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeToggleGuard {
  constructor(
    private cognitoAuthService: CognitoAuthService,
    private rdrToastService: RdrToastService,
    private rdrAuthService: RdrAuthService,
    private featureToggleService: FeatureToggleService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const feature = (route.data.feature as RdrFeature) || '';

    return this.cognitoAuthService.hasCognitoSession().pipe(
      concatMap(() => this.rdrAuthService.getUser()),
      map(() => {
        const hasPermission = !!this.featureToggleService.isFeatureEnabled(feature);

        if (!hasPermission) {
          this.rdrToastService.info({
            message: 'This page has restricted access',
          });
        }

        return hasPermission;
      }),
    );
  }
}
