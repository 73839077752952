import { Injectable } from '@angular/core';
import {
  BookingConnection,
  BookingFilter,
  ClientConnection,
  ClientFilter,
  GlobalSearch_BookingsGQL,
  GlobalSearch_ClientsGQL,
  GlobalSearch_LeadsGQL,
  GlobalSearch_OrdersGQL,
  LeadConnection,
  LeadFilter,
  LeadStatusType,
  OrderConnection,
  OrderFilter,
  UserNode,
} from '@generated/graphql';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { map } from 'rxjs/operators';
import { Maybe } from '@app/shared/types';
import { GLOBAL_SEARCH_QUERY_LIMIT } from '@ui/global-search/constants';
import { ALWAYS_EXCLUDED_ORDER_STATUSES } from '@app/shared/constants';

type Visibility = 'my' | 'other';

type searchArgs = {
  term: string;
  visibility?: Visibility;
  teamMembers?: Maybe<UserNode[]>;
  withoutUsersFilter?: boolean;
  searchLeads?: boolean;
  isOnlySalesAgent?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService {
  constructor(
    private cognitoAuth: CognitoAuthService,
    private clientsGQL: GlobalSearch_ClientsGQL,
    private leadsGQL: GlobalSearch_LeadsGQL,
    private globalSearch_OrdersGQL: GlobalSearch_OrdersGQL,
    private globalSearch_BookingsGQL: GlobalSearch_BookingsGQL,
  ) {}

  searchClients({ term, visibility, teamMembers = [], withoutUsersFilter }: searchArgs) {
    const filters: ClientFilter = this._getFilters({
      term,
      visibility,
      teamMembers,
      withoutUsersFilter,
    });

    return this.clientsGQL
      .watch(
        { filters, first: GLOBAL_SEARCH_QUERY_LIMIT },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .valueChanges.pipe(map(({ data }) => data.clients as ClientConnection));
  }

  searchLeads({ term, visibility, teamMembers = [], isOnlySalesAgent }: searchArgs) {
    const statusNotIn = [
      LeadStatusType.Qualified,
      LeadStatusType.Mirror,
      LeadStatusType.ManualFlightRequest,
      ...(isOnlySalesAgent && visibility === 'other'
        ? [LeadStatusType.Unqualified, LeadStatusType.Junk, LeadStatusType.BadNumber]
        : []),
    ];

    const filters: LeadFilter = {
      ...this._getFilters({ term, visibility, teamMembers, searchLeads: true }),
      and: [{ statusNotIn }],
    };

    return this.leadsGQL
      .watch({ filters, first: GLOBAL_SEARCH_QUERY_LIMIT })
      .valueChanges.pipe(map(({ data }) => data.leads as LeadConnection));
  }

  searchHotLeads({ term }: searchArgs) {
    const filters = {
      and: [{ phonesContains: [term] }, { status: LeadStatusType.Hot }],
    };

    return this.leadsGQL
      .watch({ filters, first: GLOBAL_SEARCH_QUERY_LIMIT })
      .valueChanges.pipe(map(({ data }) => data.leads as LeadConnection));
  }

  searchOrders({ term, teamMembers = [], withoutUsersFilter }: searchArgs) {
    const teamMembersIds: string[] = teamMembers?.map((el) => el.id) || [];

    const filters: OrderFilter = {
      and: [
        { statusNotIn: [...ALWAYS_EXCLUDED_ORDER_STATUSES] },
        { or: [{ passenger: `${term}` }, { idLike: `${term}` }] },
        {
          ...(withoutUsersFilter
            ? {}
            : { salesAgentIdIn: [this.cognitoAuth.user.id, ...teamMembersIds] }),
        },
      ],
    };

    return this.globalSearch_OrdersGQL
      .watch({ filters, first: GLOBAL_SEARCH_QUERY_LIMIT })
      .valueChanges.pipe(map(({ data }) => data.orders as OrderConnection));
  }

  searchBookings({ term, teamMembers = [], withoutUsersFilter }: searchArgs) {
    const teamMembersIds: string[] = teamMembers?.map((el) => el.id) || [];

    const filters: BookingFilter = {
      or: [
        { pnr: `%${term}%` },
        { eTicketCodeNumber: `%${term}%` },
        { confirmationNumber: `%${term}%` },
        { idLike: `${term}` },
      ],
    };

    if (!withoutUsersFilter) {
      filters.and = [
        { salesAgentIdIn: [this.cognitoAuth.user.id, ...teamMembersIds] },
        ...(filters.and || []),
      ];
    }

    if (this.cognitoAuth.isTicketingAgent || this.cognitoAuth.isAccountant) {
      filters.and = [{ excludeOptions: true }, ...(filters.and || [])];
    }

    return this.globalSearch_BookingsGQL
      .watch({ filters, first: GLOBAL_SEARCH_QUERY_LIMIT })
      .valueChanges.pipe(map(({ data }) => data.bookings as BookingConnection));
  }

  private _getFilters({
    term,
    visibility,
    teamMembers,
    withoutUsersFilter,
    searchLeads,
  }: searchArgs): ClientFilter | LeadFilter {
    const teamMembersIds: string[] = teamMembers?.map((el) => el.id) || [];

    let filters: ClientFilter | LeadFilter;

    if (searchLeads) {
      filters = {
        or: [
          { phonesContains: [term] },
          { hasEmail: term },
          { firstNameIlike: `%${term}%` },
          { middleNameIlike: `%${term}%` },
          { lastNameIlike: `%${term}%` },
          { name: term },
        ],
      };
    } else {
      filters = {
        ...getClientSearchFilters(term),
      };
    }

    if (!withoutUsersFilter) {
      const members = [this.cognitoAuth.user.id, ...teamMembersIds];
      const andConstraint = visibility === 'my' ? 'salesAgentIdIn' : 'salesAgentIdNotIn';
      filters[andConstraint] = members;
    }

    return filters;
  }
}

const getClientSearchFilters = (search: string): ClientFilter => {
  return {
    or: [{ phonesContains: [search] }, { hasEmail: search }, { name: search }],
  };
};
