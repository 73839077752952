import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';

@Injectable({
  providedIn: 'root',
})
export class SuperadminGuard {
  constructor(
    private cognitoAuthService: CognitoAuthService,
    private router: Router,
  ) {}

  canActivate() {
    const hasAccess =
      (this.cognitoAuthService.user.isActive && this.cognitoAuthService.user.superadmin) ||
      this.cognitoAuthService.user.isSuperadminContext;

    if (hasAccess) {
      return true;
    }

    void this.router.navigate(['']);
    return false;
  }
}
