import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { AlertEntityType, AlertNode } from '@app/generated/graphql';
import { AlertsService } from '../../services/alerts.service';
import { RoutingService } from '@app/shared/services/routing.service';
import { alertsLimit } from '../../utils/config';

@Component({
  selector: 'alerts-list',
  templateUrl: './alerts-list.component.html',
  styleUrls: ['./alerts-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsListComponent implements OnInit {
  alerts: AlertNode[] = [];

  limit = alertsLimit;
  offset = 0;

  loading: boolean;
  loadedAll: boolean;
  isShowActions: boolean;

  @Output() markEmitter = new EventEmitter();
  @Output() hide = new EventEmitter();

  constructor(
    private alertsService: AlertsService,
    private cdRef: ChangeDetectorRef,
    private routingService: RoutingService,
  ) {}

  ngOnInit() {
    this.getAlerts();
  }

  getAlerts() {
    this.loading = true;

    const params = { limit: this.limit, offset: this.offset };

    this.alertsService.getAlerts(params).subscribe((alerts: AlertNode[]) => {
      this.alerts = this.alerts.concat(alerts);

      this.loading = false;
      this.loadedAll = alerts.length != this.limit;

      this.cdRef.detectChanges();
    });
  }

  loadMore() {
    this.offset = this.offset + this.limit;
    this.getAlerts();
  }

  redirectTo(alert: AlertNode, event: MouseEvent) {
    const path: string[] = [alert.entityId.toString()];

    switch (alert.entityType) {
      case AlertEntityType.Client:
        path.unshift('clients');
        break;
      case AlertEntityType.FlightRequest:
        path.unshift('flight-requests');
        break;
      case AlertEntityType.Lead:
        path.unshift('leads');
        break;
      case AlertEntityType.Order:
        path.unshift('orders');
        break;
      case AlertEntityType.SearchTask:
        path.unshift('flight-requests');
    }

    this.routingService.navigateTo(path, event);
  }

  markAlert(alert: AlertNode, event?: MouseEvent) {
    const read = event ? true : !alert.read;

    const input = {
      limit: this.limit + this.offset || this.limit,
      offset: 0,
      read,
      id: alert.id,
    };

    this.alertsService.markAlertRead({ input }).subscribe((alerts) => {
      this.alerts = alerts;
      this.markEmitter.emit();

      if (event) {
        this.redirectTo(alert, event);
        this.hide.emit();
      }
    });
  }

  markAllAsRead() {
    const input = {
      limit: this.limit + this.offset || this.limit,
      offset: 0,
    };

    this.isShowActions = false;

    this.alertsService.markAllAlertsRead({ input }).subscribe((alerts) => {
      this.alerts = alerts;
      this.markEmitter.emit();
    });
  }
}
