import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CognitoAuthService } from '@auth/services/cognito-auth.service';
import { concatMap, map } from 'rxjs/operators';
import { RdrToastService } from '@ui/services/rdr-toast.service';
import { RdrAuthService } from '@auth/services/rdr-auth.service';
import { UserType } from '@app/generated/graphql';

@Injectable({
  providedIn: 'root',
})
export class SalesDirectorGuard {
  constructor(
    private cognitoAuthService: CognitoAuthService,
    private toastService: RdrToastService,
    private rdrAuth: RdrAuthService,
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.cognitoAuthService.hasCognitoSession().pipe(
      concatMap(() => this.rdrAuth.getUser()),
      map((user) => {
        const isSalesDirOrAdmin =
          Boolean(user?.salesDirector) || user?.type === UserType.Administrator;

        if (!isSalesDirOrAdmin) {
          this.toastService.info({ message: 'This page has restricted access' });
        }

        return isSalesDirOrAdmin;
      }),
    );
  }
}
