import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { CognitoAuthService } from '@app/auth/services/cognito-auth.service';
import { RdrAuthService } from '@app/auth/services/rdr-auth.service';
import { Observable, concatMap, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearcherGuard {
  constructor(
    private cognitoAuthService: CognitoAuthService,
    private router: Router,
    private rdrAuth: RdrAuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.cognitoAuthService.hasCognitoSession().pipe(
      concatMap(() => this.rdrAuth.getUser()),
      map(() => {
        if (this.cognitoAuthService.isSales) {
          if (route.queryParams.id) {
            void this.router.navigate(['flight-requests', route.queryParams.id]);

            return false;
          }
        }

        return true;
      }),
    );
  }
}
